import { API_ROOT } from "./config";
import axios from "axios";
import useEncryption from "./useEncryption";
// import store from "../redux/store";

// export default JsonInterceptor;
const customAxios = (contentType) => {
  // axios instance for making requests
  const axiosInstance = axios.create({
    baseURL: API_ROOT
  });

  axiosInstance.interceptors.request.use(
    async (config) => {
      const userInfo = JSON.parse(localStorage.getItem("userInfo"));
      // const { auth } = store.getState();
      // const {user} = auth;

      if (userInfo?.authorization) {
        config.headers.Authorization = userInfo?.authorization;
      }

      config.headers["Content-Type"] = contentType ? contentType : "application/json";
      return config;
    },
    (error) => {
      if (error.response && error.response) {
        return Promise.reject(error.response);
      }
      return Promise.reject(error);
    }
  );
  axiosInstance.interceptors.response.use(
    async (response) => {
      return process.env.REACT_APP_ENV === "production"
        ? { ...response, data: useEncryption(response.data) }
        : response;
    },
    (error) => {
      if (error.response && error.response) {
        return Promise.reject(error.response);
      }
      return Promise.reject(error);
    }
  );
  return axiosInstance;
};

const JsonInterceptor = customAxios("application/json");
export const axiosForMultipart = customAxios("multipart/form-data");

export default JsonInterceptor;
