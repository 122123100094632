import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { Badge, IconButton, Typography } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import NotificationAccordian from "./NotificationAccordian";
import CloseIcon from "@mui/icons-material/Close";
import { tokens } from "../../theme";
import { useTheme } from "@emotion/react";

export default function TemporaryDrawer({ notificationData }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event) {
      if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
        return;
      }
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      id="notificationDrawer"
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 400 }}
      role="presentation"
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={toggleDrawer(anchor, false)}
      className="adminDrawer"
    >
      <Box display="flex" justifyContent="space-between" alignItems="center" gap="6px" p="16px">
        <Box display="flex" justifyContent="flex-start" alignItems="center" gap="6px">
          <NotificationsIcon sx={{ color: colors.themeSecondaryBlueColor[100] }} />
          <Typography variant="h4" sx={{ fontWeight: "600", color: colors.themeSecondaryBlueColor[100] }}>
            Notification Center
          </Typography>
        </Box>
        <IconButton onClick={toggleDrawer(anchor, false)}>
          <CloseIcon />
        </IconButton>
      </Box>
      <NotificationAccordian notificationData={notificationData} toggleDrawer={toggleDrawer("right", false)} />
    </Box>
  );

  return (
    <>
      <IconButton onClick={toggleDrawer("right", true)}>
        {notificationData?.minStockAlert?.length > 0 ||
        notificationData?.outOfStock?.length > 0 ||
        notificationData?.customerExpiredData?.length > 0 ? (
          <Badge
            badgeContent={
              notificationData?.minStockAlert?.length +
              notificationData?.outOfStock?.length +
              notificationData?.customerExpiredData?.length
            }
            className="notificationBadge"
          >
            <NotificationsOutlinedIcon sx={{ color: "#0000008a" }} />
          </Badge>
        ) : (
          <NotificationsOutlinedIcon sx={{ color: "#0000008a" }} />
        )}
      </IconButton>
      <Drawer anchor={"right"} open={state["right"]} onClose={toggleDrawer("right", false)}>
        {list("right")}
      </Drawer>
    </>
  );
}
