import { CssBaseline, ThemeProvider } from "@mui/material";
import { ToastContainer } from "react-toastify";
import { ColorModeContext, useMode } from "./theme";
import { PrivateRoute } from "./PrivateRoute";

function App() {
  const [theme, colorMode] = useMode();
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <PrivateRoute />
        <ToastContainer theme="light" autoClose={2000}  />
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
