import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  useTheme
} from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import CloseIcon from "@mui/icons-material/Close";
import { tokens } from "../theme";

export default function MyConfirmDialog({ onClose, open, text, onClick, type }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Dialog open={open} keepMounted onClose={onClose} aria-describedby="alert-dialog-slide-description">
      <IconButton sx={{ position: "absolute", top: "5px", right: "5px" }} onClick={onClose}>
        <CloseIcon />
      </IconButton>
      <DialogTitle variant="h4" sx={{ alignItems: "center", display: "flex", p: 2 }}>
        <Box
          display="flex"
          justifyContent="space-between"
          sx={{
            alignItems: "center",
            display: "flex",
            textTransform: "capitalize"
          }}
        >
          <ErrorIcon size="medium" className="danger" />
          Are you sure?
          {/* {type === "payment"
            ? "Are you sure that you want to clear the payment ?"
            : "Are you sure that you want to change the status ?"} */}
        </Box>
      </DialogTitle>
      {/* {text && ( */}
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {/* {text} */}
          {type === "payment"
            ? "Are you sure that you want to clear the payment ?"
            : "Are you sure that you want to change the status ?"}
        </DialogContentText>
      </DialogContent>
      {/* )} */}
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => {
            onClose();
          }}
        >
          Close
        </Button>
        <Button
          variant="contained"
          sx={[
            {
              "&:hover": {
                bgcolor: colors.themeSecondaryBlueColor[100],
                color: colors.white[100]
              }
            },
            { bgcolor: colors.themeDarkGrey[100] }
          ]}
          onClick={onClick}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
