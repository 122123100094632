// tax module types
export const GET_TAXS = "GET_TAXS";
export const FETCH_TAX = "FETCH_TAX";
export const ADD_TAX = "ADD_TAX";
export const UPDATE_TAX = "UPDATE_TAX";
export const DELETE_TAX = "DELETE_TAX";

export const TAX_REQUEST = 'TAX_REQUEST';
export const TAX_SUCCESS = 'TAX_SUCCESS';
export const TAX_FAIL = 'TAX_FAIL';
export const TAX_RESET = 'TAX_RESET';

export const TAX_ADD_REQUEST = 'TAX_ADD_REQUEST';
export const TAX_ADD_SUCCESS = 'TAX_ADD_SUCCESS';
export const TAX_ADD_FAIL = 'TAX_ADD_FAIL';
export const TAX_ADD_RESET = 'TAX_ADD_RESET';

export const TAX_UPDATE_REQUEST = 'TAX_UPDATE_REQUEST';
export const TAX_UPDATE_SUCCESS = 'TAX_UPDATE_SUCCESS';
export const TAX_UPDATE_FAIL = 'TAX_UPDATE_FAIL';
export const TAX_UPDATE_RESET = 'TAX_UPDATE_RESET';

export const TAXES_REQUEST = 'TAXES_REQUEST';
export const TAXES_SUCCESS = 'TAXES_SUCCESS';
export const TAXES_FAIL = 'TAXES_FAIL';
export const TAXES_RESET = 'TAXES_RESET';

export const TAX_DELETE_REQUEST = 'TAX_DELETE_REQUEST';
export const TAX_DELETE_SUCCESS = 'TAX_DELETE_SUCCESS';
export const TAX_DELETE_FAIL = 'TAX_DELETE_FAIL';
export const TAX_DELETE_RESET = 'TAX_DELETE_RESET';


// product module types
export const PRODUCTS_REQUEST = "PRODUCTS_REQUEST";
export const PRODUCTS_SUCESSS = "PRODUCTS_SUCESSS";
export const PRODUCTS_RESET = "PRODUCTS_RESET";
export const PRODUCTS_FAIL = "PRODUCTS_FAIL";

export const PRODUCT_REQUEST = 'PRODUCT_REQUEST';
export const PRODUCT_SUCCESS = 'PRODUCT_SUCCESS';
export const PRODUCT_FAIL = 'PRODUCT_FAIL';
export const PRODUCT_RESET = 'PRODUCT_RESET';

export const PRODUCT_ADD_REQUEST = 'PRODUCT_ADD_REQUEST';
export const PRODUCT_ADD_SUCCESS = 'PRODUCT_ADD_SUCCESS';
export const PRODUCT_ADD_FAIL = 'PRODUCT_ADD_FAIL';
export const PRODUCT_ADD_RESET = 'PRODUCT_ADD_RESET';

export const PRODUCT_UPDATE_REQUEST = 'PRODUCT_UPDATE_REQUEST';
export const PRODUCT_UPDATE_SUCCESS = 'PRODUCT_UPDATE_SUCCESS';
export const PRODUCT_UPDATE_FAIL = 'PRODUCT_UPDATE_FAIL';
export const PRODUCT_UPDATE_RESET = 'PRODUCT_UPDATE_RESET';

export const PRODUCT_DELETE_REQUEST = 'PRODUCT_DELETE_REQUEST';
export const PRODUCT_DELETE_SUCCESS = 'PRODUCT_DELETE_SUCCESS';
export const PRODUCT_DELETE_FAIL = 'PRODUCT_DELETE_FAIL';
export const PRODUCT_DELETE_RESET = 'PRODUCT_DELETE_RESET';

export const SINGLE_PRODUCT_REQUEST = 'SINGLE_PRODUCT_REQUEST';
export const SINGLE_PRODUCT_SUCCESS = 'SINGLE_PRODUCT_SUCCESS';
export const SINGLE_PRODUCT_FAIL = 'SINGLE_PRODUCT_FAIL';
export const SINGLE_PRODUCT_RESET = 'SINGLE_PRODUCT_RESET';

// productDeleteFromApi

export const PRODUCT__DELETE_FROM_API_REQUEST = 'PRODUCT__DELETE_FROM_API_REQUEST';
export const PRODUCT__DELETE_FROM_API_SUCCESS = 'PRODUCT__DELETE_FROM_API_SUCCESS';
export const PRODUCT__DELETE_FROM_API_FAIL = 'PRODUCT__DELETE_FROM_API_FAIL';
export const PRODUCT__DELETE_FROM_API_RESET = 'PRODUCT__DELETE_FROM_API_RESET';
