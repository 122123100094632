import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import Layout from "./pages/global/Layout";
import Login from "./pages/login";
import { authCheck } from "./pages/login/redux/action";
import { LOG_OUT } from "./pages/login/redux/type";

export function PrivateRoute() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth, shallowEqual);

  useEffect(() => {
    if (user != null) {
      dispatch(authCheck()).then((data) => {
        if (data?.type === "AUTH_CHEK_SUCCESS") {
          if (data?.payload?.status === false) {
            dispatch({ type: LOG_OUT });
          }
        }
        if (data?.type === "AUTH_CHEK_FAIL") {
          dispatch({ type: LOG_OUT });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return user && user != null && user !== undefined ? (
    <Layout />
  ) : (
    <Routes>
      <Route exact path="/login" element={<Login />} />
      <Route path="*" element={<Navigate to="/login" replace />} />
    </Routes>
  );
}
