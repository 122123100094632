// Vendor module types
export const VendorMap = {
  VENDORS_REQUEST: "VENDORS_REQUEST",
  VENDORS_SUCCESS: "VENDORS_SUCCESS",
  VENDORS_FAIL: "VENDORS_FAIL",

  VENDORS_ADD_REQUEST: "VENDORS_ADD_REQUEST",
  VENDORS_ADD_SUCCESS: "VENDORS_ADD_SUCCESS",
  VENDORS_ADD_FAIL: "VENDORS_ADD_FAIL",

  VENDORS_UPDATE_REQUEST: "VENDORS_UPDATE_REQUEST",
  VENDORS_UPDATE_SUCCESS: "VENDORS_UPDATE_SUCCESS",
  VENDORS_UPDATE_FAIL: "VENDORS_UPDATE_FAIL",

  VENDORS_DELETE_REQUEST: "VENDORS_DELETE_REQUEST",
  VENDORS_DELETE_SUCCESS: "VENDORS_DELETE_SUCCESS",
  VENDORS_DELETE_FAIL: "VENDORS_DELETE_FAIL"
};
