import {
  GET_TAXS,
  TAX_ADD_FAIL,
  TAX_ADD_REQUEST,
  TAX_ADD_SUCCESS,
  TAX_DELETE_FAIL,
  TAX_DELETE_REQUEST,
  TAX_DELETE_SUCCESS,
  TAX_FAIL,
  TAX_REQUEST,
  TAX_UPDATE_FAIL,
  TAX_UPDATE_REQUEST,
  TAX_UPDATE_SUCCESS
} from "./type";

// category intial state
const initialTaxs = {
  taxs: [],
  isLoading: true,
  isEditLoading: true,
  taxsForEdit: null,
  error: null,
  success: false,
  total: null
};

export const reducer = (state = initialTaxs, action) => {
  switch (action.type) {
    case TAX_REQUEST:
      return { ...state, isLoading: true };
    case GET_TAXS: {
      return {
        ...state,
        taxs: action.payload.data.records,
        isLoading: false,
        success: true,
        total: action.payload.data.totalRecords
      };
    }
    case TAX_FAIL:
      return { ...state, isLoading: false, error: action.error };

    case TAX_ADD_REQUEST:
      return { ...state, isLoading: true };
    case TAX_ADD_SUCCESS:
      return { ...state, isLoading: false, success: true };
    case TAX_ADD_FAIL: {
      return { ...state, isLoading: false, error: action.error };
    }

    case TAX_UPDATE_REQUEST:
      return { ...state, isLoading: true };
    case TAX_UPDATE_SUCCESS:
      return { ...state, isLoading: false, success: true };
    case TAX_UPDATE_FAIL: {
      return { ...state, isLoading: false, error: action.error };
    }

    case TAX_DELETE_REQUEST:
      return { ...state, isLoading: true };
    case TAX_DELETE_SUCCESS:
      return { ...state, isLoading: false, success: true };
    case TAX_DELETE_FAIL: {
      return { ...state, isLoading: false, error: action.error };
    }

    default:
      return state;
  }
};
