// category module types
export const CategoryMap = {
  GET_CATEGORIES: "GET_CATEGORIES",
  FETCH_CATEGORY: "FETCH_CATEGORY",
  ADD_CATEGORY: "ADD_CATEGORY",
  UPDATE_CATEGORY: "UPDATE_CATEGORY",
  DELETE_CATEGORY: "DELETE_CATEGORY",

  CATEGORY_REQUEST: "CATEGORY_REQUEST",
  CATEGORY_SUCCESS: "CATEGORY_SUCCESS",
  CATEGORY_FAIL: "CATEGORY_FAIL",
  CATEGORY_RESET: "CATEGORY_RESET",

  CATEGORY_ADD_REQUEST: "CATEGORY_ADD_REQUEST",
  CATEGORY_ADD_SUCCESS: "CATEGORY_ADD_SUCCESS",
  CATEGORY_ADD_FAIL: "CATEGORY_ADD_FAIL",
  CATEGORY_ADD_RESET: "CATEGORY_ADD_RESET",

  CATEGORY_UPDATE_REQUEST: "CATEGORY_UPDATE_REQUEST",
  CATEGORY_UPDATE_SUCCESS: "CATEGORY_UPDATE_SUCCESS",
  CATEGORY_UPDATE_FAIL: "CATEGORY_UPDATE_FAIL",
  CATEGORY_UPDATE_RESET: "CATEGORY_UPDATE_RESET",

  CATEGORY_TREE_REQUEST: "CATEGORY_TREE_REQUEST",
  CATEGORY_TREE_SUCCESS: "CATEGORY_TREE_SUCCESS",
  CATEGORY_TREE_FAIL: "CATEGORY_TREE_FAIL",
  CATEGORY_TREE_RESET: "CATEGORY_TREE_RESET",

  SINGLE_CATEGORY_REQUEST: "SINGLE _CATEGORY_REQUEST",
  SINGLE_CATEGORY_SUCCESS: "SINGLE _CATEGORY_SUCCESS",
  SINGLE_CATEGORY_FAIL: "SINGLE _CATEGORY_FAIL",
  SINGLE_CATEGORY_RESET: "SINGLE _CATEGORY_RESET",

  CATEGORY_DELETE_REQUEST: "CATEGORY_DELETE_REQUEST",
  CATEGORY_DELETE_SUCCESS: "CATEGORY_DELETE_SUCCESS",
  CATEGORY_DELETE_FAIL: "CATEGORY_DELETE_FAIL",
  CATEGORY_DELETE_RESET: "CATEGORY_DELETE_RESET",

  TAX_DELETE_REQUEST: "TAX_DELETE_REQUEST",
  TAX_DELETE_SUCCESS: "TAX_DELETE_SUCCESS",
  TAX_DELETE_FAIL: "TAX_DELETE_FAIL",
  TAX_DELETE_RESET: "TAX_DELETE_RESET",

  STATUS_UPDATE_REQUEST: "STATUS_UPDATE_REQUEST",
  STATUS_UPDATE_SUCCESS: "STATUS_UPDATE_SUCCESS",
  STATUS_UPDATE_FAIL: "STATUS_UPDATE_FAIL"
};
