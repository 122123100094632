import { VendorMap } from "./type";

// category intial state
const initialProducts = {
  vendors: null,
  isLoading: false,
  error: null,
  success: false,
  total: null
};

export const reducer = (state = initialProducts, action) => {
  switch (action.type) {
    case VendorMap.VENDORS_REQUEST:
      return { ...state, isLoading: true };
    case VendorMap.VENDORS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        vendors: action.payload.data.records,
        total: action.payload.data.totalRecords
      };
    }
    case VendorMap.VENDORS_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case VendorMap.VENDORS_ADD_REQUEST:
      return { ...state, isLoading: true };
    case VendorMap.VENDORS_ADD_SUCCESS: {
      return {
        ...state,
        isLoading: false
      };
    }
    case VendorMap.VENDORS_ADD_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case VendorMap.VENDORS_UPDATE_REQUEST:
      return { ...state, isLoading: true };
    case VendorMap.VENDORS_UPDATE_SUCCESS: {
      return {
        ...state,
        isLoading: false
      };
    }
    case VendorMap.VENDORS_UPDATE_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case VendorMap.VENDORS_DELETE_REQUEST:
      return { ...state, isLoading: true };
    case VendorMap.VENDORS_DELETE_SUCCESS: {
      return {
        ...state,
        isLoading: false
      };
    }
    case VendorMap.VENDORS_DELETE_FAIL:
      return { ...state, isLoading: false, error: action.error };
    default:
      return state;
  }
};
