import { UserMap } from "./type";

// category intial state
const initialProducts = {
  users: [],
  isLoading: false,
  error: null,
  success: false,
  total: null,
  companies: []
};

export const reducer = (state = initialProducts, action) => {
  switch (action.type) {
    case UserMap.USERS_REQUEST:
      return { ...state, isLoading: true };
    case UserMap.USERS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        users: action.payload.data.records,
        total: action.payload.data.totalRecords
      };
    }
    case UserMap.USERS_FAIL:
      return { ...state, isLoading: false, error: action.error };

    case UserMap.ALL_COMPANIES_REQUEST:
      return { ...state, isLoading: true };
    case UserMap.ALL_COMPANIES_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        companies: action.payload.data
      };
    }
    case UserMap.ALL_COMPANIES_FAIL:
      return { ...state, isLoading: false, error: action.error };

    case UserMap.USERS_ADD_REQUEST:
      return { ...state, isLoading: true };
    case UserMap.USERS_ADD_SUCCESS: {
      return {
        ...state,
        isLoading: false
      };
    }
    case UserMap.USERS_ADD_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case UserMap.USERS_UPDATE_REQUEST:
      return { ...state, isLoading: true };
    case UserMap.USERS_UPDATE_SUCCESS: {
      return {
        ...state,
        isLoading: false
      };
    }
    case UserMap.USERS_UPDATE_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case UserMap.USERS_DELETE_REQUEST:
      return { ...state, isLoading: true };
    case UserMap.USERS_DELETE_SUCCESS: {
      return {
        ...state,
        isLoading: false
      };
    }
    case UserMap.USERS_DELETE_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case UserMap.USERS_ROLE_REQUEST:
      return { ...state, isLoading: true };
    case UserMap.USERS_ROLE_SUCCESS: {
      return {
        ...state,
        isLoading: false
      };
    }
    case UserMap.USERS_ROLE_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case UserMap.USERS_ROLE_ASSIGN_REQUEST:
      return { ...state, isLoading: true };
    case UserMap.USERS_ROLE_ASSIGN_SUCCESS: {
      return {
        ...state,
        isLoading: false
      };
    }

    case UserMap.USERS_ROLE_ASSIGN_FAIL:
      return { ...state, isLoading: false, error: action.error };
    default:
      return state;
  }
};
