import { InventoryMap } from "./type";

// category intial state
const initialProducts = {
  inventories: [],
  inventory: null,
  isLoading: false,
  error: null,
  success: false,
  total: null,
  productlist: [],
  subproductlist: [],
  vendorlist: [],
  islastINLoading: false
};

export const reducer = (state = initialProducts, action) => {
  switch (action.type) {
    case InventoryMap.INVENTORY_REQUEST:
      return { ...state, isLoading: true };
    case InventoryMap.INVENTORY_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        inventories: action.payload.data.records,
        total: action.payload.data.totalRecords
      };
    }
    case InventoryMap.INVENTORY_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case InventoryMap.INVENTORY_ADD_REQUEST:
      return { ...state, isLoading: true };
    case InventoryMap.INVENTORY_ADD_SUCCESS: {
      return {
        ...state,
        isLoading: false
      };
    }
    case InventoryMap.INVENTORY_ADD_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case InventoryMap.INVENTORY_UPDATE_REQUEST:
      return { ...state, isLoading: true };
    case InventoryMap.INVENTORY_UPDATE_SUCCESS: {
      return {
        ...state,
        isLoading: false
      };
    }
    case InventoryMap.INVENTORY_UPDATE_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case InventoryMap.INVENTORY_DELETE_REQUEST:
      return { ...state, isLoading: true };
    case InventoryMap.INVENTORY_DELETE_SUCCESS: {
      return {
        ...state,
        isLoading: false
      };
    }
    case InventoryMap.INVENTORY_DELETE_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case InventoryMap.INVENTORY_SINGLE_REQUEST:
      return { ...state, isLoading: true };
    case InventoryMap.INVENTORY_SINGLE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        inventory: action.payload.data
      };
    }
    case InventoryMap.INVENTORY_SINGLE_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case InventoryMap.PRODUCTLIST_REQUEST:
      return { ...state, isLoading: true };
    case InventoryMap.PRODUCTLIST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        productlist: action.payload.data
      };
    }
    case InventoryMap.PRODUCTLIST_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case InventoryMap.SUBPRODUCTLIST_REQUEST:
      return { ...state, isLoading: true };
    case InventoryMap.SUBPRODUCTLIST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        subproductlist: action.payload.data
      };
    }
    case InventoryMap.SUBPRODUCTLIST_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case InventoryMap.VENDORLIST_REQUEST:
      return { ...state, isLoading: true };
    case InventoryMap.VENDORLIST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        vendorlist: action.payload.data
      };
    }
    case InventoryMap.VENDORLIST_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case InventoryMap.LAST_INVENTORY_REQUEST:
      return { ...state, islastINLoading: true };
    case InventoryMap.LAST_INVENTORY_SUCCESS: {
      return {
        ...state,
        islastINLoading: false
      };
    }
    case InventoryMap.LAST_INVENTORY_FAIL:
      return { ...state, islastINLoading: false, error: action.error };
    case InventoryMap.INVENTORY_SINGLE_RESET:
      return { ...state, inventory: null };
    default:
      return state;
  }
};
