import { LayoutMap } from "./type";
import { API_END_POINTS } from "../../../utils/config";
import JsonInterceptor from "../../../utils/JsonInterceptor";

// get toggle state
export const getToggleState = (state) => async (dispatch) => {
  return dispatch({
    type: LayoutMap.GET_TOGGLE_STATE,
    payload: state
  });
};

export const getNotification = () => async (dispatch) => {
  let apiEndpoint = `${API_END_POINTS.fetchNotification}`;
  const response = await JsonInterceptor.get(`${apiEndpoint}`);
  var res = response.data; //useEncryption(response.data);
  return dispatch({
    type: LayoutMap.GET_NOTIFICATION,
    payload: res
  });
};
