import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { useDispatch } from "react-redux";
import { Box, Button, TextField, Grid, FormControl, useTheme, DialogTitle, IconButton } from "@mui/material";
import { useForm } from "react-hook-form";
import ErrorMSG from "../../components/ErrorMSG";
import { tokens } from "../../theme";
import { authCheckCaptcha, authRefreshCaptcha } from "./redux/action";
import CachedIcon from "@mui/icons-material/Cached";
import CloseIcon from "@mui/icons-material/Close";

export default function CaptchComponent({ open, handleClose, isLoading, captcha, email, setCaptcha }) {
  const dispatch = useDispatch();

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // main state to store the payload
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues: {
      captcha: "",
      email: email
    }
  });
  const onSubmit = async (data) => {
    if (Object.keys(errors).length === 0) {
      await dispatch(authCheckCaptcha(data)).then(async (data) => {
        if (data && data.payload) {
          if (data && data.payload.status) {
            reset({
              captcha: ""
            });
            handleClose();
          }
        }
      });
    }
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth
        maxWidth={"sm"}
        open={open}
        onClose={() => {
          handleClose();
        }}
      >
        <IconButton
          sx={{ position: "absolute", top: "1%", right: "1%" }}
          onClick={() => {
            handleClose();
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle className="modal-header">Enter captcha code</DialogTitle>
        <div className="captchaImg py-3 mb-3 mx-auto">
          <h2 className="mb-0">{captcha}</h2>
          <button
            type="button"
            onClick={() => {
              dispatch(authRefreshCaptcha({ email: email })).then((data) => {
                if (data.type === "REFRESH_CAPTCHA_SUCCESS") {
                  setCaptcha(data.payload.data);
                }
              });
            }}
          >
            <CachedIcon />
          </button>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          {/* <DialogTitle className="modal-header">Captcha</DialogTitle> */}
          <DialogContent>
            <Grid container>
              <Grid item xs={12} xl={12}>
                <Box m="10px">
                  <Grid item xs={12} xl={12} sx={{ mx: "auto" }}>
                    <Box display="grid" gap="20px" mt="10px">
                      <FormControl>
                        <TextField
                          fullWidth
                          type="text"
                          size="small"
                          label={
                            <p className="my-0">
                              Captcha <span className="danger mr-0">*</span>
                            </p>
                          }
                          InputLabelProps={{ shrink: true }}
                          {...register("captcha", { required: true })}
                        />
                        {errors.captcha && errors.captcha.type === "required" && (
                          <ErrorMSG text="Captcha is required" />
                        )}
                      </FormControl>
                    </Box>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions
            sx={{
              bgcolor: "#fff",
              borderTop: 1,
              borderColor: "#d5d4d4",
              position: "sticky",
              bottom: "0"
            }}
          >
            <Button
              variant="outlined"
              onClick={() => {
                handleClose();
              }}
            >
              Close
            </Button>
            <Button
              type="submit"
              variant="contained"
              sx={[
                {
                  "&:hover": {
                    bgcolor: colors.themeSecondaryBlueColor[100],
                    color: colors.white[100]
                  }
                },
                { bgcolor: colors.themeDarkGrey[100] }
              ]}
            >
              {isLoading ? "Submitting..." : "Submit"}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
}
