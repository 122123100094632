import { CategoryMap } from "./type";
import { TAXES_FAIL, TAXES_REQUEST, TAXES_SUCCESS } from "../../tax/redux/type";

// category intial state
const initialCategories = {
  categories: null,
  categoryTreeList: null,
  isLoading: false,
  error: null,
  success: false,
  total: null,
  dropDownTaxes: null
};

export const reducer = (state = initialCategories, action) => {
  switch (action.type) {
    case CategoryMap.CATEGORY_REQUEST:
      return { ...state, isLoading: true };
    case CategoryMap.GET_CATEGORIES: {
      const categories = action.payload.data.records;
      return {
        ...state,
        categories: categories,
        isLoading: false,
        total: action.payload.data.totalRecords
      };
    }
    case CategoryMap.CATEGORY_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case CategoryMap.CATEGORY_ADD_REQUEST:
      return { ...state, isLoading: true };
    case CategoryMap.CATEGORY_ADD_SUCCESS:
      return { ...state, isLoading: false, success: true };
    case CategoryMap.CATEGORY_ADD_FAIL: {
      return { ...state, isLoading: false, error: action.error };
    }
    case CategoryMap.CATEGORY_TREE_REQUEST:
      return { ...state, isLoading: true };
    case CategoryMap.CATEGORY_TREE_SUCCESS: {
      const categoriesTreeList = action.payload.data;
      return {
        ...state,
        categoriesTreeList: categoriesTreeList,
        isLoading: false
      };
    }
    case CategoryMap.CATEGORY_TREE_FAIL: {
      return { ...state, isLoading: false, error: action.error };
    }
    case TAXES_REQUEST:
      return { ...state, isLoading: true };
    case TAXES_SUCCESS:
      const dropDownTaxes = action.payload.data;
      return {
        ...state,
        dropDownTaxes: dropDownTaxes,
        isLoading: false,
        success: true
      };
    case TAXES_FAIL: {
      return { ...state, isLoading: false, error: action.error };
    }
    case CategoryMap.CATEGORY_UPDATE_REQUEST:
      return { ...state, isLoading: true };
    case CategoryMap.CATEGORY_UPDATE_SUCCESS:
      return { ...state, isLoading: false, success: true };
    case CategoryMap.CATEGORY_UPDATE_FAIL: {
      return { ...state, isLoading: false, error: action.error };
    }
    case CategoryMap.CATEGORY_DELETE_REQUEST:
      return { ...state, isLoading: true };
    case CategoryMap.CATEGORY_DELETE_SUCCESS:
      return { ...state, isLoading: false, success: true };
    case CategoryMap.CATEGORY_DELETE_FAIL: {
      return { ...state, isLoading: false, error: action.error };
    }
    case CategoryMap.STATUS_UPDATE_REQUEST:
      return { ...state, isLoading: true };
    case CategoryMap.STATUS_UPDATE_SUCCESS:
      return { ...state, isLoading: false, success: true };
    case CategoryMap.STATUS_UPDATE_FAIL: {
      return { ...state, isLoading: false, error: action.error };
    }
    default:
      return state;
  }
};
