import * as React from "react";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Box, Button, TextField, Grid, FormControl, useTheme, Select, MenuItem } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import ErrorMSG from "../../components/ErrorMSG";
import { toast } from "react-toastify";
import { tokens } from "../../theme";
import MyDialog from "../../components/MyDialog";
import { addPayment, editPayment, ViewOrder } from "./redux/action";
import { styles } from "../../utils/custom";
import SingleImageClose from "../../components/SingleImageClose";

export default function PaymentAdd({ open, handleClose, id, paymentId, paymentData, singleOrder }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [paymentType, setPaymentType] = React.useState(true);
  const [files, setFiles] = React.useState([]);

  // main state to store the payload
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors }
  } = useForm({
    defaultValues: {
      type: "ACH/Wire",
      amount: "",
      isManual: "true",
      note: "",
      paymentImg: ""
    }
  });
  const { isLoading } = useSelector((state) => state.orders, shallowEqual);

  React.useEffect(() => {
    if (!open) {
      reset({
        type: "ACH/Wire",
        amount: "",
        isManual: "true",
        note: "",
        paymentImg: ""
      });
    }
    // eslint-disable-next-line no-use-before-define
  }, [open, reset]);

  React.useEffect(() => {
    if (paymentId) {
      setFiles(paymentData?.paymentImg);
      reset({
        type: paymentData?.type,
        amount: paymentData?.amount,
        note: paymentData?.note,
        paymentImg: paymentData?.paymentImg
      });
    } else {
      reset({
        type: "ACH/Wire",
        amount: paymentData?.amount,
        note: paymentData?.note,
        paymentImg: paymentData?.paymentImg
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentData, paymentId]);

  React.useEffect(() => {
    if (!paymentId) {
      setFiles(null);
    }
  }, [paymentId]);

  const onSubmit = async (data) => {
    let formDataInfo = new FormData();
    formDataInfo.append("type", data?.type);
    formDataInfo.append("orderId", singleOrder && singleOrder._id);
    formDataInfo.append("amount", parseInt(data?.amount));
    formDataInfo.append("note", data?.note);
    formDataInfo.append("isManual", true);
    if (data?.paymentImg?.length > 0) {
      data?.paymentImg.map((x) => {
        return formDataInfo.append("paymentImg", x);
      });
    }
    if (Object.keys(errors).length === 0) {
      if (paymentId) {
        await dispatch(editPayment(formDataInfo, paymentId)).then(async (data) => {
          if (data && data?.payload?.status) {
            toast.success(data.payload.message);
            reset({ type: "ACH/Wire", orderId: "", amount: "", isManual: "true", note: "", formDataInfo: "" });
            handleClose();
            dispatch(ViewOrder(id));
          } else {
            toast.error(data.error);
          }
        });
      } else {
        await dispatch(addPayment(formDataInfo)).then(async (data) => {
          if (data && data?.payload?.status) {
            toast.success(data.payload.message);
            reset({ type: "ACH/Wire", orderId: "", amount: "", isManual: "true", note: "", formDataInfo: "" });
            handleClose();
            dispatch(ViewOrder(id));
          } else {
            toast.error(data.error);
          }
        });
      }
    }
  };

  return (
    <React.Fragment>
      <MyDialog
        maxWidth="sm"
        open={open}
        handleClose={() => {
          handleClose();
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <DialogTitle className="modal-header">{paymentId ? "Edit Payment" : "Create New Payment"}</DialogTitle>
          <DialogContent>
            <Box m="10px">
              <Grid container spacing={3}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <FormControl fullWidth>
                    <label className="react-select-labels z-index-4">Type</label>
                    <Controller
                      render={({ field: { onChange, value } }) => (
                        <Select
                          styles={styles}
                          className="react-select-no-outline reactSelectFocus z-index-3 addPaymentSelect"
                          classnameprefix="react-select"
                          value={value}
                          label="Type"
                          placeholder="Select Type"
                          onChange={(e) => {
                            onChange(e.target.value);
                            if (e.target.value === "ACH/Wire" || e.target.value === "cheque") {
                              setPaymentType(true);
                            } else {
                              setPaymentType(false);
                            }
                          }}
                        >
                          <MenuItem value="ACH/Wire">ACH/Wire</MenuItem>
                          <MenuItem value="cheque">Cheque</MenuItem>
                          <MenuItem value="cash">Cash</MenuItem>
                          <MenuItem value="bank">Bank</MenuItem>
                        </Select>
                      )}
                      control={control}
                      name="type"
                    />
                    {errors.type && errors.type.type === "required" && <ErrorMSG text="Type is required" />}
                  </FormControl>
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      fullWidth
                      type="text"
                      size="small"
                      // disabled={true}
                      // value={paymentData.amount}
                      label={
                        <p className="my-0">
                          Amount <span className="danger mr-0">*</span>
                        </p>
                      }
                      InputLabelProps={{ shrink: true }}
                      {...register("amount", { required: true })}
                      inputProps={{
                        autoComplete: "amount",
                        form: {
                          autoComplete: "off"
                        }
                      }}
                    />
                    {errors.amount && errors.amount.type === "required" && <ErrorMSG text="Amount is required" />}
                  </FormControl>
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      fullWidth
                      type="text"
                      size="small"
                      label={<p className="my-0">Note</p>}
                      InputLabelProps={{ shrink: true }}
                      {...register("note")}
                      inputProps={{
                        autoComplete: "note",
                        form: {
                          autoComplete: "off"
                        }
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <FormControl sx={{ width: "100%" }}>
                    <label className={`${paymentId ? "fileUploaderLabel-11" : "fileUploaderLabel-1"} z-index-2`}>
                      Payment Slip <span className="danger mr-0">*</span>
                    </label>
                    <Controller
                      render={({ field: { onChange, value } }) => (
                        <>
                          <TextField
                            type="file"
                            size="small"
                            className="customFileInput z-index-1"
                            inputProps={{
                              multiple: false,
                              accept: "image/gif, image/jpeg, image/png, image/jpg"
                            }}
                            onChange={async (e) => {
                              let newArray = [];
                              for await (const [, value] of Object.entries(e.target.files)) {
                                const idxDot = value.name.lastIndexOf(".") + 1;
                                const extFile = value.name.substr(idxDot, value.name.length).toLowerCase();
                                if (extFile === "jpg" || extFile === "gif" || extFile === "jpeg" || extFile === "png") {
                                  newArray = [...newArray, value];
                                } else {
                                  return toast.error("Only jpg/jpeg, gif and png files are allowed!");
                                }
                              }
                              onChange(newArray);
                              setFiles(newArray);
                            }}
                            InputLabelProps={{ shrink: true }}
                          />
                        </>
                      )}
                      control={control}
                      rules={{ required: paymentType ? true : false }}
                      name="paymentImg"
                    />
                    <SingleImageClose files={files} setFiles={setFiles} />
                    {errors.paymentImg && errors.paymentImg.type === "required" && <ErrorMSG text="File is required" />}
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={() => {
                handleClose();
              }}
            >
              Close
            </Button>
            <Button
              type="submit"
              variant="contained"
              sx={[
                {
                  "&:hover": {
                    bgcolor: colors.themeSecondaryBlueColor[100],
                    color: colors.white[100]
                  }
                },
                { bgcolor: colors.themeDarkGrey[100] }
              ]}
            >
              {isLoading ? "Saving..." : "Save"}
            </Button>
          </DialogActions>
        </form>
      </MyDialog>
    </React.Fragment>
  );
}
