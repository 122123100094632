import React, { useState, Suspense, lazy } from "react";
import Topbar from "./Topbar";
import Sidebar from "./Sidebar";
import { Box } from "@mui/system";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import UserRole from "../user/UserRole";
import GetUserRole from "../../utils/GetUserRole";
import GlobalLoader from "../../components/GlobalLoader";
import OrderView from "../order-management/OrderView";
import { shallowEqual, useSelector } from "react-redux";
const BarCode = lazy(() => import("../barcode/BarCode"));
const InventoryView = lazy(() => import("../inventory/InventoryView"));
const Inventory = lazy(() => import("../inventory/InventoryTable"));
const Vendor = lazy(() => import("../vendor/VendorTable"));
const CustomerView = lazy(() => import("../customer/customerview/CustomerView"));
const Customer = lazy(() => import("../customer/CustomerTable"));
const User = lazy(() => import("../user/UserTable"));
const ProductView = lazy(() => import("../product/ProductView"));
const Product = lazy(() => import("../product/ProductTable"));
const Category = lazy(() => import("../category/CategoryTable"));
const Tax = lazy(() => import("../tax/TaxTable"));
const Dashboard = lazy(() => import("../dashboard"));
const OrderManagement = lazy(() => import("../order-management/OrderTable"));
// const TestTable = lazy(() => import("../order-management/TestTable"));
const CreateOrder = lazy(() => import("../order-management/CreateOrder"));

export default function Layout() {
  const [isSidebar, setIsSidebar] = useState(true);
  const { user } = useSelector(
    (state) => ({
      user: state && state.auth.user
    }),
    shallowEqual
  );
  const location = useLocation();
  return (
    <Box sx={{ display: "flex", flexGrow: 1, width: "100%" }}>
      {location.pathname !== "/orders/create" && (
        <Box
          sx={{
            minWidth: 0,
            flex: "1 0 auto",
            overflowY: "auto"
          }}
          height="100vh"
        >
          <Sidebar isSidebar={isSidebar} />
        </Box>
      )}
      <Box sx={{ minWidth: 0, flex: "0 1 auto", overflowY: "auto" }} height="100vh" width="100%">
        {location.pathname !== "/orders/create" && <Topbar setIsSidebar={setIsSidebar} />}
        <Suspense fallback={<GlobalLoader />}>
          <Routes>
            <Route exact path="/dashboard" element={<Dashboard />} />
            {GetUserRole("user_list") && <Route exact path="/users" element={<User />} />}
            {user?.userType === "admin" && <Route path="/users/role/:id" element={<UserRole />} />}
            {GetUserRole("customer_list") && <Route path="/customers" element={<Customer />} />}
            {GetUserRole("customer_view") && <Route path="/customers/view/:id" element={<CustomerView />} />}
            {GetUserRole("category_list") && <Route exact path="/category" element={<Category />} />}
            {GetUserRole("tax_list") && <Route exact path="/tax" element={<Tax />} />}
            {GetUserRole("product_list") && <Route exact path="/products" element={<Product />} />}
            {GetUserRole("product_view") && <Route exact path="/products/view/:id" element={<ProductView />} />}
            {GetUserRole("vendor_list") && <Route exact path="/vendors" element={<Vendor />} />}
            {GetUserRole("inventory_list") && <Route exact path="/inventory" element={<Inventory />} />}
            {GetUserRole("inventory_list") && <Route exact path="/inventory/:id" element={<Inventory />} />}
            {GetUserRole("inventory_view") && <Route exact path="/inventory/view/:id" element={<InventoryView />} />}
            {GetUserRole("order_list") && <Route exact path="/orders" element={<OrderManagement />} />}
            {GetUserRole("order_add") && <Route exact path="/orders/create" element={<CreateOrder />} />}
            {GetUserRole("order_view") && <Route exact path="/orders/view/:id" element={<OrderView />} />}
            {GetUserRole("barcode_generate") && <Route exact path="/barcode" element={<BarCode />} />}
            {/* {GetUserRole("barcode_generate") && <Route exact path="/testTable" element={<TestTable />} />} */}
            <Route path="*" element={<Navigate to="/dashboard" replace />} />
          </Routes>
        </Suspense>
      </Box>
    </Box>
  );
}
