import * as React from "react";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import ImageIcon from "@mui/icons-material/Image";
import ImageViewer from "react-simple-image-viewer";
const baseUrl = process.env.REACT_APP_BACKEND_URI;

export default function SingleImageClose({ files, setFiles, rowData, type, sellerFiles }) {
  // const dispatch = useDispatch();
  const [isViewerOpen, setIsViewerOpen] = React.useState(false);
  React.useEffect(() => {}, [rowData]);
  const images = [`${baseUrl}/${files?.fileUrl}`];

  const closeImageViewer = () => {
    setIsViewerOpen(false);
  };

  return (
    <>
      {/* {(type === "paotp" || type === "subProductImg") && ( */}
      <>
        {isViewerOpen && (
          <ImageViewer
            src={images}
            currentIndex={"0"}
            onClose={closeImageViewer}
            disableScroll={false}
            backgroundStyle={{
              backgroundColor: "rgba(0,0,0,0.9)"
            }}
            closeOnClickOutside={false}
          />
        )}
        {files && files.fileName && (
          <Box sx={{ position: "relative" }}>
            <Box
              sx={{ width: "100%", mt: 0.5 }}
              className="cursor-pointer imageAlertThumb"
              onClick={() => {
                setIsViewerOpen(true);
              }}
            >
              <Alert icon={<ImageIcon fontSize="inherit" />} severity="info">
                {files.fileName}
              </Alert>
            </Box>
          </Box>
        )}
      </>
      {/* )} */}
      {/* {type === "saller" && (
        <>
          {isViewerOpen && (
            <ImageViewer
              src={images}
              currentIndex={"0"}
              onClose={closeImageViewer}
              disableScroll={false}
              backgroundStyle={{
                backgroundColor: "rgba(0,0,0,0.9)"
              }}
              closeOnClickOutside={false}
            />
          )}
          {sellerFiles && sellerFiles.fileName && (
            <Box sx={{ position: "relative" }}>
              <Box
                sx={{ width: "100%", mt: 0.5 }}
                className="cursor-pointer imageAlertThumb"
                onClick={() => {
                  setIsViewerOpen(true);
                }}
              >
                <Alert icon={<ImageIcon fontSize="inherit" />} severity="info">
                  
                  {sellerFiles.fileName}
                </Alert>
              </Box>
            </Box>
          )}
        </>
      )} */}
    </>
  );
}
