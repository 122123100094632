import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { Badge, Box } from "@mui/material";
import moment from "moment";
import { useNavigate, useSearchParams } from "react-router-dom";
import { tokens } from "../../theme";
import { useTheme } from "@emotion/react";

const format1 = "MM-DD-YYYY";

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0
  },
  "&:before": {
    display: "none"
  }
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem", color: "#fff" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)"
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1)
  }
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)"
}));

export default function NotificationAccordian({ notificationData, toggleDrawer }) {
  const navigate = useNavigate();
  const [expanded, setExpanded] = React.useState("panel1");

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  let [searchParams] = useSearchParams();
  const [urlState, setUrlInventory] = React.useState(true);
  const [custometUrlState, setCustomerUrlInventory] = React.useState(true);

  React.useEffect(() => {
    if (searchParams.get("addInvenory") === "true") {
      setUrlInventory(false);
    }
  }, [searchParams, urlState]);

  React.useEffect(() => {
    if (searchParams.get("editCustomer") === "true") {
      setCustomerUrlInventory(false);
    }
  }, [searchParams, custometUrlState]);

  return (
    <div>
      <Accordion expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" className="notifictionAccHead">
          <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
            <Typography>Min Stock Alert</Typography>
            {notificationData?.minStockAlert.length > 0 && (
              <Badge className="accordianNotificationBadge">{notificationData?.minStockAlert.length}</Badge>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails className="notificationAccDetails">
          {notificationData?.minStockAlert.map((text, i) => {
            return (
              <Box
                key={`list-1${i}`}
                className="notifyListItem"
                bgcolor={i % 2 ? "#f2f0f0" : "transparent"}
                width="100%"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p="8px 16px"
                component="button"
                sx={{ border: "none", textAlign: "left" }}
                onClick={() => {
                  toggleDrawer();
                  navigate(`/inventory/${text?.subProductData?._id}/?addInvenory=${urlState}`);
                }}
              >
                <Box>
                  <Box mb="4px">
                    <Typography variant="h6" fontWeight="600" color={colors.greenAccent[500]}>
                      {text?.productData?.name ? text?.productData?.name : "N/A"}
                    </Typography>
                    <Typography variant="h6" fontWeight="600">
                      {text?.subProductData?.SKU ? text?.subProductData?.SKU : "N/A"} -{" "}
                      {text?.subProductData?.name ? text?.subProductData?.name : "N/A"}
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <Box mb="4px" textAlign="center">
                    <Typography variant="h6" fontWeight="600" sx={{ minWidth: "max-content" }}>
                      {text?.stockCount ? text?.stockCount : "N/A"}
                    </Typography>
                    <Typography variant="h6" sx={{ minWidth: "max-content" }}>
                      Current Stock
                    </Typography>
                  </Box>
                </Box>
              </Box>
            );
          })}
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === "panel2"} onChange={handleChange("panel2")}>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header" className="notifictionAccHead">
          <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
            <Typography>Out Of Stock</Typography>
            {notificationData?.outOfStock.length > 0 && (
              <Badge className="accordianNotificationBadge">{notificationData?.outOfStock.length}</Badge>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails className="notificationAccDetails">
          {notificationData?.outOfStock.map((text, i) => {
            return (
              <Box
                key={`list-2${i}`}
                className="notifyListItem"
                bgcolor={i % 2 ? "#f2f0f0" : "transparent"}
                width="100%"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p="8px 16px"
                component="button"
                sx={{ border: "none", textAlign: "left" }}
                onClick={() => {
                  toggleDrawer("right", false);
                  navigate(`/inventory/${text?.subProductData?._id}/?addInvenory=${urlState}`);
                }}
              >
                <Box>
                  <Box mb="4px">
                    <Typography variant="h6" fontWeight="600" color={colors.greenAccent[500]}>
                      {text?.productData?.name ? text?.productData?.name : "N/A"}
                    </Typography>
                    <Typography variant="h6" fontWeight="600">
                      {text?.subProductData?.SKU ? text?.subProductData?.SKU : "N/A"} -{" "}
                      {text?.subProductData?.name ? text?.subProductData?.name : "N/A"}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            );
          })}
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === "panel3"} onChange={handleChange("panel3")}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header" className="notifictionAccHead">
          <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
            <Typography>PA OTP Expired Date</Typography>
            {notificationData?.customerExpiredData.length > 0 && (
              <Badge className="accordianNotificationBadge">{notificationData?.customerExpiredData.length}</Badge>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails className="notificationAccDetails">
          {notificationData?.customerExpiredData.map((text, i) => {
            return (
              <Box
                key={`list-3${i}`}
                className="notifyListItem"
                bgcolor={i % 2 ? "#f2f0f0" : "transparent"}
                width="100%"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p="8px 16px"
                component="button"
                sx={{ border: "none", textAlign: "left" }}
                onClick={() => {
                  toggleDrawer();
                  navigate(`/customers/view/${text?._id}/?editCustomer=${custometUrlState}`);
                }}
              >
                <Box>
                  <Box mb="4px">
                    <Typography variant="h6" fontWeight="600" color={colors.greenAccent[500]}>
                      {text?.companyName ? text?.companyName : "N/A"}
                    </Typography>
                    <Typography variant="h6" fontWeight="600">
                      {text?.firstName} {text?.lastName}
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <Box textAlign="center">
                    <Typography variant="h6" fontWeight="600" sx={{ minWidth: "max-content" }}>
                      {moment(text?.paOTPExpireDate).format(format1)}
                    </Typography>
                    <Typography variant="h6" sx={{ minWidth: "max-content" }}>
                      Expiry Date
                    </Typography>
                  </Box>
                </Box>
              </Box>
            );
          })}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
