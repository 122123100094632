export default function GetTreeViewData(data) {
  let firstStep = { ...data?.find((x) => x.roleId === 1) };
  let treeOfRoles = {
    value: firstStep.roleKey,
    label: firstStep.roleName,
    children: []
  };
  data
    ?.filter((x) => x.subRoleId === 1)
    .forEach((role) => {
      if (role.subRoleId === 1) {
        treeOfRoles.children[role.roleId] = {
          value: role.roleKey,
          label: role.roleName,
          children: data
            ?.filter((y) => y.subRoleId === role.roleId)
            .map((z) => {
              return {
                value: z.roleKey,
                label: z.roleName
              };
            })
        };
      }
    });
  return treeOfRoles;
}
