import { DashboardMap } from "./type";

// inventory intial state
const initialDashboardDetails = {
  dashboardDetailsFlag1: null,
  dashboardDetailsFlag2: null,
  dashboardDetailsFlag3: null,
  dashboardDetailsFlag4: null,
  dashboardDetailsFlag5: null,
  inventoryCount: null,
  error: null,
  isLoading: false,
  dash1Loader: true,
  dash2Loader: true
};

export const reducer = (state = initialDashboardDetails, action) => {
  switch (action.type) {
    case DashboardMap.GET_DASHBOARD_DETAILS_FLAG1_REQUESTED: {
      const isLoading = true;
      return {
        ...state,
        isLoading: isLoading,
        error: null,
        dash1Loader: true
      };
    }
    case DashboardMap.GET_DASHBOARD_DETAILS_FLAG1: {
      const dashboardDetailsFlag1 = action.payload.data;
      return {
        ...state,
        dashboardDetailsFlag1: dashboardDetailsFlag1,
        isLoading: false,
        dash1Loader: false
      };
    }
    case DashboardMap.GET_DASHBOARD_DETAILS_FLAG1_FAILED: {
      const error = action.payload;
      return {
        ...state,
        error: error,
        isLoading: false,
        dash1Loader: false
      };
    }
    case DashboardMap.GET_DASHBOARD_DETAILS_FLAG2: {
      const dashboardDetailsFlag2 = action.payload;
      return {
        ...state,
        dashboardDetailsFlag2: dashboardDetailsFlag2
      };
    }
    case DashboardMap.GET_DASHBOARD_DETAILS_FLAG3: {
      const dashboardDetailsFlag3 = action.payload;
      return {
        ...state,
        dashboardDetailsFlag3: dashboardDetailsFlag3
      };
    }
    case DashboardMap.GET_DASHBOARD_DETAILS_FLAG5: {
      const dashboardDetailsFlag5 = action.payload;
      return {
        ...state,
        dashboardDetailsFlag5: dashboardDetailsFlag5
      };
    }
    case DashboardMap.GET_DASHBOARD_DETAILS_FLAG4: {
      const dashboardDetailsFlag4 = action.payload;
      return {
        ...state,
        dashboardDetailsFlag4: dashboardDetailsFlag4
      };
    }

    default:
      return state;
  }
};
