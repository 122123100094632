import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import {
  AUTH_CHEK_FAIL,
  AUTH_CHEK_REQUEST,
  AUTH_CHEK_SUCCESS,
  CHEK_CAPTCHA_FAIL,
  CHEK_CAPTCHA_REQUEST,
  CHEK_CAPTCHA_SUCCESS,
  LOG_OUT,
  PASSWORD_CHANGE_FAIL,
  PASSWORD_CHANGE_REQUEST,
  PASSWORD_CHANGE_SUCCESS,
  REFRESH_CAPTCHA_FAIL,
  REFRESH_CAPTCHA_REQUEST,
  REFRESH_CAPTCHA_SUCCESS,
  USER_SIGNIN_FAIL,
  USER_SIGNIN_SUCCESS
} from "./type";

// Auth intial state
const initialAuthState = {
  user: JSON.parse(localStorage.getItem("userInfo")) ? JSON.parse(localStorage.getItem("userInfo")) : null,
  isLoading: true,
  error: null
};

export const reducer = persistReducer(
  // Set persist key
  {
    storage,
    key: "v713-demo1-auth",
    whitelist: ["user"]
  },

  (state = initialAuthState, action) => {
    switch (action.type) {
      case USER_SIGNIN_SUCCESS: {
        return {
          ...state,
          user: action.payload.data,
          isLoading: false
        };
      }
      case USER_SIGNIN_FAIL:
        return { ...state, isLoading: false, error: action.payload };
      case LOG_OUT:
        return { ...state, isLoading: false, user: null };
      case AUTH_CHEK_REQUEST:
        return { ...state, isLoading: true };
      case AUTH_CHEK_SUCCESS: {
        return {
          ...state,
          isLoading: false,
          user: action.payload.data
        };
      }
      case AUTH_CHEK_FAIL:
        return { ...state, isLoading: false, error: action.error };
      case REFRESH_CAPTCHA_REQUEST:
        return { ...state, isLoading: true };
      case REFRESH_CAPTCHA_SUCCESS: {
        return {
          ...state,
          isLoading: false
        };
      }
      case REFRESH_CAPTCHA_FAIL:
        return { ...state, isLoading: false, error: action.error };
      case CHEK_CAPTCHA_REQUEST:
        return { ...state, isLoading: true };
      case CHEK_CAPTCHA_SUCCESS: {
        return {
          ...state,
          isLoading: false
        };
      }

      case PASSWORD_CHANGE_REQUEST:
        return { ...state, isLoading: false, error: action.error };
      case PASSWORD_CHANGE_SUCCESS:
        return { ...state, isLoading: true };
      case PASSWORD_CHANGE_FAIL: {
        return {
          ...state,
          isLoading: false
        };
      }
      case CHEK_CAPTCHA_FAIL:
        return { ...state, isLoading: false, error: action.error };
      default:
        return state;
    }
  }
);
