// tax module types
export const GET_TAXS = "GET_TAXS";
export const FETCH_TAX = "FETCH_TAX";
export const ADD_TAX = "ADD_TAX";
export const UPDATE_TAX = "UPDATE_TAX";
export const DELETE_TAX = "DELETE_TAX";

export const TAX_REQUEST = 'TAX_REQUEST';
export const TAX_SUCCESS = 'TAX_SUCCESS';
export const TAX_FAIL = 'TAX_FAIL';
export const TAX_RESET = 'TAX_RESET';

export const TAX_ADD_REQUEST = 'TAX_ADD_REQUEST';
export const TAX_ADD_SUCCESS = 'TAX_ADD_SUCCESS';
export const TAX_ADD_FAIL = 'TAX_ADD_FAIL';
export const TAX_ADD_RESET = 'TAX_ADD_RESET';

export const TAX_UPDATE_REQUEST = 'TAX_UPDATE_REQUEST';
export const TAX_UPDATE_SUCCESS = 'TAX_UPDATE_SUCCESS';
export const TAX_UPDATE_FAIL = 'TAX_UPDATE_FAIL';
export const TAX_UPDATE_RESET = 'TAX_UPDATE_RESET';

export const TAXES_REQUEST = 'TAXES_REQUEST';
export const TAXES_SUCCESS = 'TAXES_SUCCESS';
export const TAXES_FAIL = 'TAXES_FAIL';
export const TAXES_RESET = 'TAXES_RESET';

export const TAX_DELETE_REQUEST = 'TAX_DELETE_REQUEST';
export const TAX_DELETE_SUCCESS = 'TAX_DELETE_SUCCESS';
export const TAX_DELETE_FAIL = 'TAX_DELETE_FAIL';
export const TAX_DELETE_RESET = 'TAX_DELETE_RESET';
